// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import {
  ConnectKeyResponse,
  ConnectivityResponse,
  LoggerAlarmSettings,
  LoggerInfoResponse,
  LoggerNotificationSettings,
  LoggerOnboardingRequest,
  LoggerOnboardingResponse,
  MultipleLoggersSettingsRequest,
  ProductSimulationSettingsResponse,
  SensorInfo,
  UpdateProductSimulationRequest
} from '../models';

export interface CheckLoggerAlreadyOnboardedUsingGETRequest {
  serialNumber: string;
}

export interface GetLoggerAlarmSettingsUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetLoggerInfoUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetLoggerNotificationSettingsUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetSensorInfoUsingGETRequest {
  thingId: string;
}

export interface OffboardLoggerUsingDELETERequest {
  virtualFridgeId: string;
}

export interface ReadProductSimulationSettingsUsingGETRequest {
  thingId: string;
}

export interface SaveLoggerAlarmSettingsUsingPUTRequest {
  virtualFridgeId: string;
  loggerAlarmSettings: LoggerAlarmSettings;
}

export interface StartLoggerOnboardingUsingPOSTRequest {
  groupId: string;
  serialNumber: string;
  loggerOnboardingRequest: LoggerOnboardingRequest;
}

export interface UpdateLoggerNotificationSettingsUsingPUTRequest {
  loggerNotificationSettings: LoggerNotificationSettings;
}

export interface UpdateMultipleLoggersNotificationSettingsUsingPUTRequest {
  multipleLoggersSettingsRequest: MultipleLoggersSettingsRequest;
}

export interface UpdateProductSimulationSettingsUsingPUTRequest {
  updateProductSimulationRequest: UpdateProductSimulationRequest;
}

/**
 * no description
 */
export class LoggerControllerApi extends BaseAPI {
  /**
   * checkLoggerAlreadyOnboarded
   * Check if logger is already onboarded.
   */
  checkLoggerAlreadyOnboardedUsingGET = (
    requestParameters: CheckLoggerAlreadyOnboardedUsingGETRequest
  ): Observable<ConnectivityResponse> => {
    throwIfRequired(requestParameters, 'serialNumber', 'checkLoggerAlreadyOnboardedUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ConnectivityResponse>({
      path: '/logger/check/{serialNumber}'.replace('{serialNumber}', encodeURI(requestParameters.serialNumber)),
      method: 'GET',
      headers
    });
  };

  /**
   * getConnectKey
   * Get connect key.
   */
  getConnectKeyUsingGET = (): Observable<ConnectKeyResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ConnectKeyResponse>({
      path: '/logger/connectkey',
      method: 'GET',
      headers
    });
  };

  /**
   * getLoggerAlarmSettings
   * Get the logger alarm settings by VFID.
   */
  getLoggerAlarmSettingsUsingGET = (
    requestParameters: GetLoggerAlarmSettingsUsingGETRequest
  ): Observable<LoggerAlarmSettings> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getLoggerAlarmSettingsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<LoggerAlarmSettings>({
      path: '/logger/{virtualFridgeId}/alarmsettings'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * getLoggerInfo
   * Get logger info by virtual fridge id
   */
  getLoggerInfoUsingGET = (requestParameters: GetLoggerInfoUsingGETRequest): Observable<LoggerInfoResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getLoggerInfoUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<LoggerInfoResponse>({
      path: '/logger/{virtualFridgeId}/info'.replace('{virtualFridgeId}', encodeURI(requestParameters.virtualFridgeId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getLoggerNotificationSettings
   * Gets Logger notification settings by given virtualFridgeId.
   */
  getLoggerNotificationSettingsUsingGET = (
    requestParameters: GetLoggerNotificationSettingsUsingGETRequest
  ): Observable<LoggerNotificationSettings> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getLoggerNotificationSettingsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<LoggerNotificationSettings>({
      path: '/logger/notification/settings/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * getSensorInfo
   * Get common sensor info.
   */
  getSensorInfoUsingGET = (requestParameters: GetSensorInfoUsingGETRequest): Observable<SensorInfo> => {
    throwIfRequired(requestParameters, 'thingId', 'getSensorInfoUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SensorInfo>({
      path: '/logger/sensor/{thingId}/info'.replace('{thingId}', encodeURI(requestParameters.thingId)),
      method: 'GET',
      headers
    });
  };

  /**
   * offboardLogger
   * Off-boards a logger
   */
  offboardLoggerUsingDELETE = (requestParameters: OffboardLoggerUsingDELETERequest): Observable<void> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'offboardLoggerUsingDELETE');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/logger/{virtualFridgeId}/offboarding'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'DELETE',
      headers
    });
  };

  /**
   * readProductSimulationSettings
   * Reads product simulation settings
   */
  readProductSimulationSettingsUsingGET = (
    requestParameters: ReadProductSimulationSettingsUsingGETRequest
  ): Observable<ProductSimulationSettingsResponse> => {
    throwIfRequired(requestParameters, 'thingId', 'readProductSimulationSettingsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ProductSimulationSettingsResponse>({
      path: '/logger/sensor/settings/{thingId}'.replace('{thingId}', encodeURI(requestParameters.thingId)),
      method: 'GET',
      headers
    });
  };

  /**
   * saveLoggerAlarmSettings
   * Saves the alarm settings for a logger and returns the saved object again
   */
  saveLoggerAlarmSettingsUsingPUT = (
    requestParameters: SaveLoggerAlarmSettingsUsingPUTRequest
  ): Observable<LoggerAlarmSettings> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'saveLoggerAlarmSettingsUsingPUT');
    throwIfRequired(requestParameters, 'loggerAlarmSettings', 'saveLoggerAlarmSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<LoggerAlarmSettings>({
      path: '/logger/{virtualFridgeId}/alarmsettings'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'PUT',
      headers,
      body: requestParameters.loggerAlarmSettings
    });
  };

  /**
   * startLoggerOnboarding
   * Launch logger onboarding flow.
   */
  startLoggerOnboardingUsingPOST = (
    requestParameters: StartLoggerOnboardingUsingPOSTRequest
  ): Observable<LoggerOnboardingResponse> => {
    throwIfRequired(requestParameters, 'groupId', 'startLoggerOnboardingUsingPOST');
    throwIfRequired(requestParameters, 'serialNumber', 'startLoggerOnboardingUsingPOST');
    throwIfRequired(requestParameters, 'loggerOnboardingRequest', 'startLoggerOnboardingUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<LoggerOnboardingResponse>({
      path: '/logger/onboarding/{serialNumber}/{groupId}'
        .replace('{groupId}', encodeURI(requestParameters.groupId))
        .replace('{serialNumber}', encodeURI(requestParameters.serialNumber)),
      method: 'POST',
      headers,
      body: requestParameters.loggerOnboardingRequest
    });
  };

  /**
   * updateLoggerNotificationSettings
   * Create/Update logger notification settings by virtualFridgeId.
   */
  updateLoggerNotificationSettingsUsingPUT = (
    requestParameters: UpdateLoggerNotificationSettingsUsingPUTRequest
  ): Observable<boolean> => {
    throwIfRequired(requestParameters, 'loggerNotificationSettings', 'updateLoggerNotificationSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/logger/notification/settings',
      method: 'PUT',
      headers,
      body: requestParameters.loggerNotificationSettings
    });
  };

  /**
   * updateMultipleLoggersNotificationSettings
   * Multiplies logger notification settings by logger Id.
   */
  updateMultipleLoggersNotificationSettingsUsingPUT = (
    requestParameters: UpdateMultipleLoggersNotificationSettingsUsingPUTRequest
  ): Observable<boolean> => {
    throwIfRequired(
      requestParameters,
      'multipleLoggersSettingsRequest',
      'updateMultipleLoggersNotificationSettingsUsingPUT'
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/logger/multiply/notification/settings',
      method: 'PUT',
      headers,
      body: requestParameters.multipleLoggersSettingsRequest
    });
  };

  /**
   * updateProductSimulationSettings
   * Update product simulation settings
   */
  updateProductSimulationSettingsUsingPUT = (
    requestParameters: UpdateProductSimulationSettingsUsingPUTRequest
  ): Observable<ProductSimulationSettingsResponse> => {
    throwIfRequired(requestParameters, 'updateProductSimulationRequest', 'updateProductSimulationSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ProductSimulationSettingsResponse>({
      path: '/admin/logger/sensor/settings',
      method: 'PUT',
      headers,
      body: requestParameters.updateProductSimulationRequest
    });
  };
}
